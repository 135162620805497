<template>
  <b-row class="mb-5">
    <b-col
      cols="12"
    >
      <h2 class="text-dark fw-bold-700 mb-3 size24">
        Riwayat
      </h2>
      <app-timeline class="mb-2">
        <h6 class="text-primary" v-if="histories.length == 0">History belum ada</h6>
        <app-timeline-item
          v-for="(history, index) in histories"
          :key="index"
          :title="history.title"
          :subtitle="history.time"
          :by="`oleh ${history.operator_name}`"
          :image="history && history.properties !== [] && history.properties.price ? history.properties.price.price_type : ''"
          :price-from="history && history.properties !== [] && history.properties.price ? history.properties.price.from : ''"
          :price-to="history && history.properties !== [] && history.properties.price ? history.properties.price.to : ''"
          :buying_price="history.properties.buying_price"
          :minimum_price="history.properties.minimum_price"
          variant="success"
        />
      </app-timeline>
      <a
        v-if="next_page_url"
        class="text-success ml-4 size14 fw-bold-700"
        style="color:#2C948A!important"
        :disabled="isLoading"
        @click="getData"
      >
        <b-spinner small v-if="isLoading" /> Lihat Selengkapnya
      </a>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    uuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      histories: [],
      next_page_url: '',
      isLoading: false,
      page: 1,
      pagePlus: 1,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      await this.$store.dispatch('parentProduct/getHistory', {
        id: this.$route.params.id || this.uuid,
        params: {
          // eslint-disable-next-line no-plusplus
          page: this.pagePlus,
          per_page: 3,
        },
      }).then(result => {
        // this.histories = result.data.data.data
        result.data.data.data.forEach(element => {
          this.histories.push(element)
        })
        this.next_page_url = result.data.data.next_page_url
        this.pagePlus = result.data.data.current_page + 1
        this.isLoading = false
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
// Timeline
.timeline-variant-success {
  .timeline-item-point {
    background-color: unset !important;
    &::before {
      background-color: unset !important;
      background: linear-gradient(180deg, #45B6AB 0%, rgba(69, 182, 171, 0.5) 100%);
      box-shadow: inset 1.67273px 1.67273px 3.34545px rgb(230 219 211 / 20%);
    }
  }
}
</style>
