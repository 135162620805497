<template>
  <b-modal
    id="modal-save"
    hide-header
    hide-footer
    centered
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <div class="p-2 d-flex justify-content-center text-center">
      <h4 class="text-dark font-bold-700 size16 mb-0">
        Apakah data kamu sudah benar?
      </h4>
    </div>
    <b-container class="my-md-3 my-2">
      <b-row class="p-2">
        <b-col cols="12">
          <h6 class="text-center text-dark text-darken-4 fw-bold-600 size16">
            Simpan semua informasi produk, harga, diskon, dan stok dari produk {{ typeModal }}.
          </h6>
        </b-col>
      </b-row>
    </b-container>
    <div class="button__group">
      <b-button
        class="button__cancel"
        @click="$bvModal.hide('modal-save')"
      >
        Batal
      </b-button>
      <b-button
        class="button__save d-flex align-items-center justify-content-center"
        :disabled="isLoadingSave"
        @click.prevent="editId ? updateItem() : submitData()"
      >
        <div v-if="isLoadingSave">
          <b-spinner variant="primary" />
        </div>
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BModal, BButton, BContainer, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BButton,
    BSpinner,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    submitData: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    updateItem: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    editId: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    typeModal: {
      type: String,
    },
  },
  watch: {
    editId(value) {
    },
    isLoading(value) {
      this.isLoadingSave = value
    },
  },
  data() {
    return {
      isLoadingSave: false,
    }
  },
}
</script>

<style lang="scss">
@import '/src/assets/scss/variables/_variables.scss';

#modal-save {
  .modal-dialog {

    .modal-content {
      background: #FCFCFC;
      border-radius: 16px;

      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: #E7E7ED;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__cancel {
            background-color: #FFFFFF !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45B6AB !important;
            border-color: #45B6AB !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

        }
      }
    }
  }
}
</style>
