<template>
  <div class="table__container mt-1 mb-3">
    <h2 class="text-dark fw-bold-700 mb-2 size24">Pembelian Terakhir</h2>

    <div
      class="d-flex justify-content-center mb-1 mt-5"
      v-if="isLoading == true"
    >
      <b-spinner label="Loading..." />
    </div>

    <vue-good-table
      v-else
      class="bg-white border-x"
      max-height="80vh"
      :columns="columns"
      :rows="rows.data"
      :fixed-header="false"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template slot="table-column" slot-scope="props">
        <span
          v-if="props.column.field === 'total'"
          style="display: block; width: 100%; text-align: left"
        >
          <span>
            {{ props.column.label }}
          </span>
        </span>

        <span v-else>
          <span>
            {{ props.column.label }}
          </span>
        </span>
      </template>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'po_so_id'">
          <span class="text-capitalize">
            {{ props.row.po_so_id || "-" }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'payment_method'">
          <span class="text-capitalize">
            {{ props.row.payment_method || "-" }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'total'">
          <span>
            {{ props.row.total | formatAmount }}
          </span>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10', '15', '20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan
          {{
            rows.total > 0 ? filter.per_page * (rows.current_page - 1) + 1 : 0
          }}
          ke
          {{
            rows.total > 0
              ? Math.min(filter.per_page * rows.current_page, rows.total)
              : 0
          }}
          dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getIncomingStock"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BDropdown,
  BDropdownItem,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BRow,
  BCol,
  BSpinner,
} from "bootstrap-vue";
// import { mapActions } from "vuex";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BRow,
    BCol,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Tanggal Pembelian",
          field: "date",
        },
        {
          label: "No PO",
          field: "po_so_id",
        },
        {
          label: "No Transaksi",
          field: "payment_number",
        },
        {
          label: "Pemasok",
          field: "supplier.name",
        },
        {
          label: "Metode Bayar",
          field: "payment_method",
        },
        {
          label: "Total Harga",
          field: "total",
        },
      ],
      rows: {},
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
        sort_by: null,
      },
      page: 1,
      uuid: null,
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getIncomingStock();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getIncomingStock();
  },
  methods: {
    async getIncomingStock(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.page = page;
      this.$store
        .dispatch("parentProduct/getStock", {
          params: queryParams,
          uuid: this.$route.params.id,
        })
        .then((result) => {
          this.isLoading = false;
          this.rows = result.data.data;
        })
        .catch((err) => {
          if (err.response.data.meta.messages) {
            errorNotification(this, "Oops!", err.response.data.meta.messages);
          }
          this.isLoading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.border-x {
  border-left: 1px solid #ebe9f1;
  border-right: 1px solid #ebe9f1;
}

table {
  &.vgt-table {
    border: none;
  }
  th {
    text-align: left;
  }
}

.vgt-responsive {
  height: calc(100vh - 322px) !important;
  background-color: #fff;
}
</style>
