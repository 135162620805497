<template>
  <div class="mb-2">
    <b-row>
      <b-col
        v-if="$route.params.id && checkPermission('harga modal')"
        cols="12"
        class="mb-2"
      >
        <h2 class="text-dark fw-bold-700 mb-3 size24">
          Harga dan diskon
        </h2>
        <div class="price__discount--box">
          <h6 class="text-dark text-darken-4 fw-bold-600 size14">
            Harga modal berdasarkan rata-rata dari harga pembelian
          </h6>
          <h4 class="text-dark fw-bold-800 size24">
            <span class="size14">Rata-rata</span> {{ formPayload.avg_purchase_price | formatAmount }}
          </h4>
        </div>
      </b-col>

      <!-- Harga Modal (Detail) -->
      <b-col
        cols="12"
        md="4"
        class="mb-2 custom__form--input"
        v-if="this.$route.params.id && checkPermission('harga modal')"
      >
        <b-form-group
          label-for="v-harga-standar"
        >
          <label
            for=""
            class="d-flex justify-content-between align-items-center"
            style="position: relative;"
          >
            Harga Modal (Optional)
            <feather-icon
              icon="AlertCircleIcon"
              role="button"
              @click="showTooltip = true"
              @mouseenter="showTooltip = true"
            />
            <Transition name="fade">
              <div
                v-if="showTooltip"
                id="talkbubble"
              >
                <div class="title">
                  Harga Modal
                </div>
                <div class="content-tooltip">
                  Agar mendapatkan laporan laba-rugi produk ini, Anda perlu mengisi harga modal ini.
                </div>
                <div class="footer">
                  <b-button @click="showTooltip = false">
                    Ok, Mengerti
                  </b-button>
                </div>
              </div>
            </Transition>
          </label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend
              is-text
              class="w-100"
              :class="Array.isArray(messages.buying_price) ? 'error-validation' : ''"
            >
              <span class="text-dark fw-bold-600 size14">Rp</span>
              <money
                id="v-harga-standar"
                v-model="formPayload.avg_purchase_price"
                v-bind="money"
                :class="Array.isArray(messages.buying_price) ? 'error-validation' : ''"
                placeholder="Harga Jual Standar"
                class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8"
                :disabled="this.$route.params.id"
              />
            </b-input-group-prepend>
          </b-input-group>
          <small
            v-if="messages.buying_price && Array.isArray(messages.buying_price) && messages.buying_price.length > 0"
            class="text-error fw-bold-500 size12"
          >{{ messages.buying_price.join(', ') }}</small>
        </b-form-group>
      </b-col>
      
      <!-- Harga Modal (Tambah) -->
      <b-col
        cols="12"
        md="4"
        class="mb-2 custom__form--input"
        v-if="!this.$route.params.id && checkPermission('harga modal')"
      >
        <b-form-group
          label-for="v-harga-standar"
        >
          <label
            for=""
            class="d-flex justify-content-between align-items-center"
            style="position: relative;"
          >
            Harga Modal (Optional)
            <feather-icon
              icon="AlertCircleIcon"
              role="button"
              @click="showTooltip = true"
              @mouseenter="showTooltip = true"
            />
            <Transition name="fade">
              <div
                v-if="showTooltip"
                id="talkbubble"
              >
                <div class="title">
                  Harga Modal
                </div>
                <div class="content-tooltip">
                  Agar mendapatkan laporan laba-rugi produk ini, Anda perlu mengisi harga modal ini.
                </div>
                <div class="footer">
                  <b-button @click="showTooltip = false">
                    Ok, Mengerti
                  </b-button>
                </div>
              </div>
            </Transition>
          </label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend
              is-text
              class="w-100"
              :class="Array.isArray(messages.buying_price) ? 'error-validation' : ''"
            >
              <span class="text-dark fw-bold-600 size14">Rp</span>
              <money
                id="v-harga-standar"
                v-model="formPayload.buying_price"
                v-bind="money"
                :class="Array.isArray(messages.buying_price) ? 'error-validation' : ''"
                placeholder="Harga Jual Standar"
                class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8"
              />
            </b-input-group-prepend>
          </b-input-group>
          <small
            v-if="messages.buying_price && Array.isArray(messages.buying_price) && messages.buying_price.length > 0"
            class="text-error fw-bold-500 size12"
          >{{ messages.buying_price.join(', ') }}</small>
        </b-form-group>
      </b-col>
      
      <!-- Harga Jual Minimum -->
      <b-col
        cols="12"
        md="4"
        class="mb-2 custom__form--input"
      >
        <b-form-group
          label-for="v-harga-minimum"
        >
          <label for="">Harga Jual Minimum</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend
              is-text
              class="w-100"
              :class="Array.isArray(messages.minimum_price) ? 'error-validation' : ''"
            >
              <span class="text-dark fw-bold-600 size14">Rp</span>
              <money
                id="v-harga-minimum"
                v-model="formPayload.minimum_price"
                v-bind="money"
                :class="Array.isArray(messages.minimum_price) ? 'error-validation' : ''"
                placeholder="Harga Jual Minimum"
                class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8"
              />
            </b-input-group-prepend>
          </b-input-group>
          <small
            v-if="messages.minimum_price && Array.isArray(messages.minimum_price) && messages.minimum_price.length > 0"
            class="text-error fw-bold-500 size12"
          >{{ messages.minimum_price.join(', ') }}</small>
        </b-form-group>
      </b-col>

      <!-- Harga Jual Standar -->
      <b-col
        cols="12"
        md="4"
        class="mb-2 custom__form--input"
      >
        <b-form-group
          label-for="v-harga-standar"
        >
          <label for="">Harga Jual Standard</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend
              is-text
              class="w-100"
              :class="Array.isArray(messages.standard_price) ? 'error-validation' : ''"
            >
              <span class="text-dark fw-bold-600 size14">Rp</span>
              <money
                id="v-harga-standar"
                v-model="formPayload.standard_price"
                v-bind="money"
                :class="Array.isArray(messages.standard_price) ? 'error-validation' : ''"
                placeholder="Harga Jual Standar"
                class="custom__input border-rad-top-left-0 border-rad-bottom-left-0 border-rad-top-right-8 border-rad-bottom-right-8"
              />
            </b-input-group-prepend>
          </b-input-group>
          <small
            v-if="messages.standard_price && Array.isArray(messages.standard_price) && messages.standard_price.length > 0"
            class="text-error fw-bold-500 size12"
          >{{ messages.standard_price.join(', ') }}</small>
        </b-form-group>
      </b-col>
    </b-row>
    <template v-if="$route.params.id">
      <!-- <IncomingStock /> -->
    </template>
    <b-row class="pt-1 border border-8">
      <!-- Tanggal Awal Diskon -->
      <b-col
        cols="6"
        md="6"
        class="mb-2 border-bottom"
      >
        <b-form-group
          label="Tanggal Awal Diskon"
          label-for="v-first-date"
        >
          <b-form-input
            id="v-first-date"
            v-model="formPayload.discount_start_date"
            type="date"
            :class="Array.isArray(messages.discount_start_date) ? 'error-validation' : ''"
            placeholder="dd-mm-yyyy"
            class="custom__input"
            style="padding-top: 1rem;"
          />
          <small
            v-if="messages.discount_start_date && Array.isArray(messages.discount_start_date) && messages.discount_start_date.length > 0"
            class="text-error fw-bold-500 size12"
          >{{ messages.discount_start_date.join(', ') }}</small>
        </b-form-group>
      </b-col>

      <!-- Tanggal Akhir Diskon -->
      <b-col
        cols="6"
        md="6"
        class="mb-2 border-bottom"
      >
        <b-form-group
          label="Tanggal Akhir Diskon"
          label-for="v-last-date"
        >
          <b-form-input
            id="v-last-date"
            v-model="formPayload.discount_end_date"
            type="date"
            :class="Array.isArray(messages.discount_end_date) ? 'error-validation' : ''"
            placeholder="dd-mm-yyyy"
            class="custom__input"
            style="padding-top: 1rem;"
          />
          <small
            v-if="messages.discount_end_date && Array.isArray(messages.discount_end_date) && messages.discount_end_date.length > 0"
            class="text-error fw-bold-500 size12"
          >{{ messages.discount_end_date.join(', ') }}</small>
        </b-form-group>
      </b-col>

      <b-col
        v-if="discounts && discounts.length == 0"
        cols="12"
        class="text-center mt-2"
      >
        <div class="circle">
          <b-img :src="require('@/assets/images/icons/receipt.svg')" />
        </div>
        <h6 class="text-dark size18 fw-bold-800 mt-1">
          Produk ini belum memiliki diskon
        </h6>
        <small class="text-dark text-darken-5 fw-bold-500 size14">Anda dapat menambahkan diskon nominal rupiah atau persentase harga</small>
        <br>
        <b-button
          class="mt-1 mb-5"
          @click="addDiscount"
        >
          Tambah Diskon
        </b-button>
      </b-col>

      <b-col
        v-else
        cols="12"
        class="px-0"
      >
        <b-row class="px-0 mx-0">
          <b-col
            v-for="(discount, index) in discounts"
            :key="index"
            cols="12"
            class="px-0 pb-1"
          >
            <div class="d-flex px-2 align-items-center">
              <h6
                class="mb-0 w-50"
              >
                {{ index + 1 }}. Diskon {{ discount.type === 'fixed' ? 'Rp' : '%' }}
              </h6>
              <money
                class="pl-1 form-control w-100"
                :value="discount.discount"
                v-bind="money"
                disabled
              />
              <div
                class="align-self-center text-right ml-1"
                role="button"
                @click="editDiscount(index, discount.type, discount.discount)"
              >
                <feather-icon
                  size="24"
                  icon="EditIcon"
                  class="text-primary fw-bold-800"
                />
              </div>
              <div
                class="align-self-center text-right ml-1"
                role="button"
                @click="deleteDiscount(index)"
              >
                <feather-icon
                  size="24"
                  icon="Trash2Icon"
                  class="text-danger fw-bold-800"
                />
              </div>
            </div>
            <hr class="mx-1 mb-0">
          </b-col>
        </b-row>
        <div class="d-flex align-items-center justify-content-between w-100 border-top border-bottom">
          <div
            class="d-flex align-items-center justify-content-between px-2 py-1 w-100"
            @click="addDiscount"
          >
            <div class="d-flex align-items-center">
              <div class="rounded__frame">
                <b-img
                  width="17"
                  :src="require('@/assets/images/icons/discount.svg')"
                />
              </div>
              <h6 class="text-primary font-weight-bolder mb-0">
                Masukkan diskon tambahan % atau Rp
              </h6>
            </div>
            <feather-icon
              class="text-dark font-weight-bolder"
              size="20"
              icon="PlusIcon"
            />
          </div>
        </div>
      </b-col>

    </b-row>
    <ModalAddtionalInputDiscount />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BImg,
  BButton,
  BCard,
} from "bootstrap-vue";
import ModalAddtionalInputDiscount from "@/components/Cashier/Modal/AdditionalInputDiscount.vue";
import IncomingStock from "@/components/MasterProduct/Part/IncomingStock.vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ModalAddtionalInputDiscount,
    BInputGroupPrepend,
    BImg,
    BButton,
    BCard,
    IncomingStock,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    // eslint-disable-next-line vue/prop-name-casing
    is_parent: {
      type: Boolean,
      default: true,
    },
    formData: {
      type: Object,
    },
    messages: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    priceModal: {
      type: Number,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      discounts: [],
      formPayload: {
        buying_price: 0,
        standard_price: 0,
        minimum_price: 0,
        avg_purchase_price: 0,
        discounts: [],
        discount_start_date: '',
        discount_end_date: '',
      },
      showTooltip: false,
      modal: 0,
    }
  },
  watch: {
    priceModal(value) {
      this.modal = value
    },
    formData: {
      handler(value) {
        this.discounts = value.discounts || []
        this.formPayload = value
        this.formPayload.buying_price = value.buying_price
        // this.formPayload.buying_price = value.latest_order_price > 0 ? value.latest_order_price : value.buying_price
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        this.$store.commit('parentProduct/setDiscounts', value)
        // this.$emit('payloadPrice', value)
      },
      deep: true,
    },
    discounts: {
      handler(value) {
        this.formPayload.discounts = value
      },
      deep: true,
    },
    '$store.state.cashier.discounts': {
      handler(value) {
        if (this.$store.state.cashier.indexDiscounts !== false) {
          this.discounts.splice(this.$store.state.cashier.indexDiscounts, 1)
          this.discounts.splice(this.$store.state.cashier.indexDiscounts, 0, {
            type: value.type,
            discount: value.discount,
          })
        } else {
          this.discounts.push({
            type: value.type,
            discount: value.discount,
          })
        }
      },
      deep: true,
    },
  },
  created() {
    // if (this.$route.params.id) {
    //   this.getPrice()
    // }
  },
  methods: {
    editDiscount(index, type, discount) {
      this.$store.commit('cashier/setIndexDiscounts', index)
      this.$store.commit('cashier/setDiscounts', {
        type,
        discount,
      })
      this.$store.commit('cashier/setIsAddDiscount', true)
      this.$bvModal.show('modal-additional-input-discount')
    },
    deleteDiscount(index) {
      this.discounts.splice(index, 1)
      this.summaryDiscount()
    },
    addDiscount() {
      this.$store.commit('cashier/setIndexDiscounts', false)
      this.$store.commit('cashier/setIsAddDiscount', true)
      this.$bvModal.show('modal-additional-input-discount')
    },
    async getPrice() {
      this.$store.dispatch('parentProduct/getPrice', {
        uuid: this.$route.params.id,
        params: '',
      })
        .then(result => {
          this.modal = result.data.data.modal || 0
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
#talkbubble {
  position: absolute;
  top: 30px;
  left: 95%;
  width: 320px;
  height: auto;
  background: #334155;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 1rem;
  z-index: 10;

  .title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .content-tooltip {
    font-size: 14px;
    color: #E2E8F0;
    margin-bottom: 10px;
  }

  .footer {
    button {
      background-color: #4A5A71 !important;
      border: 1px solid #4A5A71 !important;
      border-radius: 6px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: -7px;
    top: -15px;
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-right: 26px solid #334155;
    border-bottom: 13px solid transparent;
    transform: rotate(65deg);
  }
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  background: #F6F7FA;
  border-radius: 50%;
  margin: 0 auto;
}

.price__discount--box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFF4EC;
  border: 1px solid #FF8F39;
  border-radius: 8px;
  padding: 1.5rem;
}

.custom__form--input {
  label {
    font-size: 14px;
    font-weight: 600;
    color: $dark;
  }
  .custom__input {
    background: $light--1;
    border: none !important;
    box-sizing: border-box !important;
    border-radius: 8px;
    height: 53px;
    color: $dark;
    font-weight: 600;
    font-size: 14px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .input-group {
    input {
      background: $light--background;
      width: 100%;

      &:focus-visible, &:focus  {
        outline: none !important;
      }
    }

    .input-group-append {
      .input-group-text {
        width: 100%;
        height: 53px;
        border: 1px solid $light--background;
        background-color: $light--background;
        color: $light--description;
        border-radius: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .input-group-prepend {
      .input-group-text {
        width: 100%;
        height: 53px;
        border: 1px solid $light--background;
        background-color: $light--background;
        color: $light--description;
        border-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

label {
  margin-bottom: 1rem;
}

.custom__input {
  color: #2B324F !important;
  font-weight: 600;

  &:focus-visible, &:focus  {
    outline: none !important;
  }
}

.vs__selected {
  color: #2B324F !important;
  font-weight: 600;
}

</style>
